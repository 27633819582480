<template>
  <div class="users">
    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>

      <!-- <AddButton
        :create-function="openDialog"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'ADD NEW'"
      /> -->
    </v-card-actions>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :value="selected"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :no-data-text="'Data not availble in this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:top>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchRoles"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Data'"
                :append-icon="''"
              />
            </v-col>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="openDeleteDialog"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>
      </v-data-table>
      <floating-button
        :create-function="openDialog"
        :icon="'mdi-plus'"
        :title="''"
      />
    </v-card>
    <!-- <CreateButton
      :create-function="openDialog"
      :icon="' mdi-view-grid-plus-outline'"
    />-->
    <UserDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :roles="roles"
      :searchOptions="searchOptions"
      :genders="gender"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
  </div>
</template>

<script>
import UserDialog from "./Forms/MainMenuDialog";
import { get, create, update, remove } from "./services";
import { get as fetchSubmenus } from "../sub-menus/services";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    UserDialog,
  },
  name: "MainMenus",
  data() {
    return {
      roles: [],
      selected: [],
      gender: [],
      datas: [],
      title: "Manage Main Menus",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      params: {},
      item: null,
      items: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Url",
          align: "start",
          sortable: false,
          value: "url",
          class: "primary--text",
        },
        {
          text: "order",
          align: "start",
          sortable: false,
          value: "order",
          class: "primary--text",
        },
        {
          text: "Icon ",
          align: "start",
          sortable: false,
          value: "icon",
          class: "primary--text",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
      headerRoles: [
        {
          text: "Role Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
  },

  methods: {
    init(params) {
      this.loading = true;
      get({
        ...params,
      })
        .then((response) => {
          // console.log('resp', response.data[0].total)
          this.loading = false;
          this.params.total = response.data[0].total;
          this.items = response.data[0]
            ? response.data[0].data
                .map((user) => ({
                  ...user,
                  fullName:
                    user.first_name +
                    " " +
                    user.middle_name +
                    " " +
                    user.surname,
                }))
                .sort(function(a, b) {
                  if (a > b) return 1;
                  return -1;
                })
                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    textTruncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      this.getSubMenus(this.params);
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject() {
      this.loading = true;
      remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    searchRoles(optionName) {
      this.init({
        search: optionName ? optionName : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },
    searchOptions(data) {
      this.getSubMenus({
        search: data ? data : null,
      });
    },
    save(data) {
      this.loading = true;
      if (data.id) {
        update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        create(data)
          .then(() => {
            console.log("created");
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);

      console.log("niho twagoba");
    },
    getSubMenus(params) {
      fetchSubmenus({ ...params, size: 1000 }).then((response) => {
        // console.log(response.data[0].data)
        this.roles = response.data[0].data;
        //this.datas.push(...this.roles)
      });
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },
  created() {
    this.getSubMenus(this.params);
  },
  mounted() {},
};
</script>

<style scoped></style>
